import { Base } from './base.js';

export class Social extends Base {
    constructor() {
        super();
        this.socialLoginMessage = document.querySelector('.socialloginmsg');
        
        this.emailInput = document.getElementById('emailInput');
        this.passwordInput = document.getElementById('passwordInput');

        this.passwordCheckButton = document.getElementById('checkLoginButton');
        this.confirmButton = document.querySelector('.check-login-button');
        this.agbConfirm = document.getElementById('agbInput');
        this.commentsConfirm = document.getElementById('commentsInput');
        this.confirmError = document.getElementById('missingAgb');
    }

    googleLogin() {
        let ref = CONFIG.redirectUrl + "&reason=" + CONFIG.registrationReason;
        this.hideErrorMessages();
        let formData = {
            referer: ref,
            socialType: 'google',
        };

        let request = new Request('POST', '/api/get/socialloginurl');

        request.data = formData;
        request.call(response => {
            if (response.status == 'ok') {
                window.location.href = response.data.url;
            } else {
                if (this.socialLoginMessage) {
                    this.socialLoginMessage.innerHTML = "Bei der Verbindung zum Login bei Google ist ein Fehler aufgetreten. Die korrekte URL konnte nicht ermittelt werden. Bitte versuchen Sie es erneut oder wenden Sie sich an unseren Leserservice.";
                    this.showBlock('.socialloginmsg');
                }
            }
        }, () => {
            if (this.socialLoginMessage) {
                this.socialLoginMessage.innerHTML = this.errorCode.unknownError;
                this.showBlock('.socialloginmsg');
            }
        });
    }

    googleExternalLink() {
        this.pushHistoryState("googleExternalLink");
    }

    login() {
        this.pushHistoryState("socialLogin");
    }

    loginConfirm() {
        this.pushHistoryState("socialLoginConfirm");

        if (this.emailInput) {
            this.emailInput.addEventListener('change', () => {
                this.hideErrorMessages();
            });
        }

        if (this.passwordInput) {
            this.passwordInput.addEventListener('change', () => {
                this.hideErrorMessages();
            });
        }

        if (this.passwordCheckButton) {
            this.passwordCheckButton.addEventListener('click', (event) => {
                event.preventDefault();
                this.socialLoginConfirm();
            });
        }

    }

    loginConsensConfirm() {
        this.pushHistoryState("socialLoginConsensConfirm");

        if (this.agbConfirm) {
            this.agbConfirm.addEventListener('change', () => {
                this.hideErrorMessages();
            });
        }

        if (this.commentsConfirm) {
            this.commentsConfirm.addEventListener('change', () => {
                this.hideErrorMessages();
            });
        }
        
        if (this.confirmButton) {
            this.confirmButton.addEventListener('click', (event) => {
                event.preventDefault();
                this.socialConsens();
            });
        }
    }

    socialLoginConfirm() {
        if (!this.emailInput.value) {
            console.log("E-Mail nicht übermittelt oder Reload der Seite erfolgt. Leite zum Start zurück.");
            window.location.href = this.baseUrl + "/" + window.location.search;
        } else {
            this.emailInput.addEventListener("focus", () => {
                this.hideErrorMessages();
            });
        }

        if (this.passwordInput) {
            this.passwordInput.addEventListener("focus", () => {
                this.hideErrorMessages();
            });
        }

        this.buttonSpinner(this.passwordCheckButton);
        if(this.passwordInput.value != "") {
            this.hideErrorMessages();
            let formData = {
                form: 'socialProfileMergeConfirm',
                password: this.passwordInput.value,
                email: this.emailInput.value,
                linkingState: CONFIG.socialLoginGoogleParams.linkingState ? CONFIG.socialLoginGoogleParams.linkingState : '',
            };
   
            let request = new Request('POST', '/api/confirm/sociallogin');
            request.data = formData;
            request.call(response => {
                if (response.status == 'ok') {
                    if (response.data.accessToken) {
                        if (CONFIG.environment.isLocal) {
                            this.redirectUrl = this.redirectUrl + "&globalSessionId=" + response.data.accessToken;
                        }

                        let redirect = false;
                        if (response.data.tac) {
                            this.tacCookie = response.data.tac;
                            redirect = this.redirectUrl;
                        }
    
                        this.utpCookie = response.data.accessToken;
    
                        if (this.missingName) {
                            document.location.href = this.missingNameRoute;
                        } else {
                            if (redirect) {
                                document.location.href = redirect;
                            } else {
                                this.updateTacCookie();
                            }
                        }
                    } else {
                        this.buttonSpinner(".check-login-button", false);
                    }
                }
            }, () => {
                this.buttonSpinner(".check-login-button", false);
            });
        }
    }

    socialConsens() {
        this.buttonSpinner(".check-login-button");
    
        if( (! this.agbConfirm.checked) || (! this.commentsConfirm.checked) ) {
            this.hideBlock(this.confirmError);
            this.buttonSpinner(".check-login-button", false);
        } else {
            this.hideErrorMessages();

            let formData = {
                form: 'formConsensConfirm',
                additionalInputState: CONFIG.socialLoginGoogleParams.additionalInputState ? CONFIG.socialLoginGoogleParams.additionalInputState : '',
                regReason: CONFIG.registrationReason,
            };

            let request = new Request('POST', '/api/confirm/socialConsens');
            request.data = formData;
            request.call(response => {
                if (response.status == 'ok') {
                    if (response.data.accessToken) {
                        if (CONFIG.environment.isLocal) {
                            this.redirectUrl = redirectUrl + "&globalSessionId=" + response.data.accessToken;
                        }

                        let redirect = false;
                        if (response.data.tac) {
                            this.tacCookie = response.data.tac;
                            redirect = this.redirectUrl;
                        }

                        this.utpCookie = response.data.accessToken;

                        if (this.missingName) {
                            document.location.href= this.missingNameRoute;
                        } else {
                            if (redirect) {
                                document.location.href = redirect;
                            } else {
                                this.updateTacCookie();
                            }
                        }
                    } else {
                        this.buttonSpinner(".check-login-button", false);
                    }
                }
            }, () => {
                this.buttonSpinner(".check-login-button", false);
            });
        }
    }
}