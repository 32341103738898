import { Base } from './base.js';

export class RegisterUser extends Base {
    constructor() {
        super();

        this.buttonExistingUser = document.querySelector('.existinguser');
        this.buttonNewUser = document.querySelector('.newuser');
        this.buttonSkipGp = document.querySelector('#skiGp');
        this.buttonEmailChange = document.querySelector('.change-email');
        this.buttonMasterdataChange = document.querySelector('.change-masterdata');
        this.buttonPasswordInput = document.querySelector('#passwordButton');
        this.buttonRegisterCheck = document.querySelector('#registerButton > span');
        this.buttonRegister = document.querySelector('#registerButton');

        this.passwordInput = document.getElementById('passwordInput');
        this.passwordInputError = document.querySelector('.form-error-passwordinput');

        this.emailFields = document.getElementById('emailFields');
        this.emailInput = document.getElementById('emailInput');
        this.regReasonInput = document.getElementById('regReasonInput');
        this.emailText = document.getElementById('emailText');
        this.emailFixed = emailText.querySelector("p.email-text span.email-text-bold");
        this.masterDataFixed = document.querySelector('span.masterdata-text-bold');

        this.errFields = document.querySelectorAll('.form-error');
        this.fieldSalutation = document.getElementById('userinfo-salutation');
        this.fieldCompany = document.getElementById('userinfo-company');
        this.fieldFirstName = document.getElementById('userinfo-firstName');
        this.fieldLastName = document.getElementById('userinfo-lastName');
        this.fieldCustomerId = document.getElementById('userinfo-customerId');

        this.loginUrl = this.baseUrl + "/" +  window.location.search;
        // this.registrationUrl = this.baseUrl + "/registrierung" + window.location.search;

        this.btn2click = document.querySelector('.change-email');
        
        this.init();
    }

    init() {
        this.pushHistoryState('register');

        if(CONFIG.customerId != "") {
            let submittedGp = Cookies.get('submittedGp', false);
            if (submittedGp) {
                console.log("Cookie gefunden. Setze Vars und entferne Cookie.");
                this.decryptedCustomerId.status = "ok";
                this.decryptedCustomerId.gp = submittedGp;
                Cookies.remove('submittedGp', {domain: location.hostname});
                if(CONFIG.customerId != "" && this.decryptedCustomerId.status == "ok") {
                    document.querySelector('.customerId-text').innerHTML = "Kundennummer: " + this.decryptedCustomerId.gp + "<br />&nbsp;";
                }
            } else {
                console.log("Cookie NICHT gesetzt. Decrypte...");
                this.decryptGp();
            }
            if(this.decryptedCustomerId.status == "ok") {
                console.log("Vars sind jetzt gesetzt.", this.decryptedCustomerId);
            } else {
                console.log("Vars immer noch nicht gesetzt. Call noch nicht zurück.");
            }
            this.showPasswordSetForm();
            this.showCommentDataForm();
        }

        if (this.buttonExistingUser) {
            this.buttonExistingUser.addEventListener ('click', (event) => {
                event.preventDefault();
                this.btn2click = document.querySelector('.password-button');
                this.showBlock('#existingAboDataBlock');
                this.showBlock('#buttonsContainer');
                this.showBlock('#buttonsContainer');
                this.showBlock('#btnPwShowBlock');
                this.hideBlock('#existingAboBlock');
            });
        }

        if (this.buttonNewUser) {
            this.buttonNewUser.addEventListener('click', (event) => {
                event.preventDefault();
                this.showPasswordSetForm();
                this.showCommentDataForm();
            });
        }

        if (this.emailInput) {
            this.emailInput.addEventListener('focus', () => {
                this.hideErrorMessages();
            });

            let regData = Cookies.get('regdata', false);
            if (regData) {
                this.hideBlock(this.emailFields);
                this.showBlock(this.emailText)
                this.emailInput.value = regData;
                this.emailFixed.innerHTML = regData;
                Cookies.remove('regdata', {domain: location.hostname});
            } else {
                document.location.href = this.loginUrl;
            }
        }

        if (this.buttonEmailChange) {
            this.buttonEmailChange.addEventListener('click', () => {
                let regData = Cookies.get('regdata', false);
                if (!regData) {
                    Cookies.set('regdata', this.emailInput.value, { domain: location.hostname });
                } 
                document.location.href = this.loginUrl;
            });
        }

        if (this.buttonMasterdataChange) {
            this.buttonMasterdataChange.addEventListener('click', () => {
                this.btn2click = this.buttonPasswordInput;
                this.showBlock('#existingAboDataBlock');
                this.showBlock('#buttonsContainer');
                this.showBlock('#btnPwShowBlock');
                this.hideBlock('#passwordBlock');
                this.hideBlock('#btnRegisterlBlock');
                this.hideBlock('#masterDataText');
                this.setStatusbar('50');
            });
        }

        if (this.fieldSalutation) {
            this.fieldSalutation.addEventListener('change', () => {
                if(this.fieldSalutation.value == "Firma") {
                    this.showBlock('.form-group-company');
                } else {
                    this.hideBlock('.form-group-company');
                }
            });
        }

        if (this.buttonPasswordInput) {
            this.buttonPasswordInput.addEventListener ('click', (event) => {
                event.preventDefault();
                this.buttonSpinner(".btn.password-button");
                let hasError = false;
                
                this.errFields.forEach(element => {
                    this.hideBlock(element);
                });

                if (this.fieldSalutation.value == "") {
                    this.showBlock('.form-error.form-error-salutation');
                    hasError = true;
                }
                if (this.fieldSalutation.value == "Firma" &&  this.fieldCompany.value == "") {
                    this.showBlock('.form-error.form-error-company');
                    hasError = true;
                }
                if (this.fieldFirstName.value == "") {
                    this.showBlock('.form-error.form-error-firstName');
                    hasError = true;
                }
                if (this.fieldLastName.value == "") {
                    this.showBlock('.form-error.form-error-lastName');
                    hasError = true;
                }
                if (this.fieldCustomerId.value == "") {
                    this.showBlock('.form-error.form-error-customerId');
                    hasError = true;
                } else {
                    this.fieldCustomerId.value = ("0000000000" + this.fieldCustomerId.value).slice(-10); 
                }
                if (hasError) {
                    this.buttonSpinner(".btn.password-button", false);
                } else {
                    let formData = {
                        gp: this.fieldCustomerId.value,
                        lastName: this.fieldLastName.value,
                    };
        
                    let responseElement = document.querySelector('.form-error.form-error-response > p');
                    let request = new Request('POST', '/api/validate/gp');
                    request.data = formData;
                    request.call(response => {
                        
                        if (response.status == 'ok') {
                            this.btn2click = document.querySelector('.register-button');
                            
                            this.showPasswordSetForm();
                            this.hideBlock('#existingAboDataBlock');
                            this.hideBlock('#btnPwShowBlock');
                            this.showBlock('#masterDataText');
                            this.showCommentDataForm();

                            let salutationCompany = this.fieldSalutation.value == "Firma" ? this.fieldCompany.value : this.fieldSalutation.value;
                            this.masterDataFixed.innerHTML = salutationCompany + "<br />" + this.fieldFirstName.value + " " + this.fieldLastName.value + "<br />Kd-Nr.: " + this.fieldCustomerId.value;
                            if (this.emailFixed.offsetWidth > this.masterDataFixed.offsetWidth) {
                                this.masterDataFixed.style.width = this.emailFixed.offsetWidth + "px";
                            } else {
                                this.emailFixed.style.width = this.masterDataFixed.offsetWidth + "px";
                            }
                            this.buttonSpinner(".btn.password-button", false);
                        } else {
                            if (response.code == 10006) {
                                this.errorCode.login = response.data.login;
                            } 
                            this.showBlock('.form-error.form-error-response');
                            responseElement.innerHTML = "Bei der Prüfung Ihrer Kundennummer ist ein Fehler aufgetreten: <br />" + this.errorCode.getByCode(response.code);
                            this.buttonSpinner(".btn.password-button", false);
                        }
                    }, () => {
                        this.buttonSpinner(".btn.password-button", false);
                    });
                }
            });
        }

        if (this.passwordInput) {
            this.passwordInput.addEventListener('focus', () => {
                this.hideBlock(this.passwordInputError);
            });
        }

        if(this.buttonRegisterCheck) {
            this.buttonRegisterCheck.addEventListener ('click', (event) => {
                event.preventDefault();
                if (!event.isTrigger) {
                    if (this.buttonRegister.disabled) {
                        document.querySelector('.form-error-passwordinput span').innerHTML = "Bitte geben Sie ein Passwort ein, das aus mindestens 6 Zeichen, darunter midestens eine Ziffer, besteht.";
                        this.showBlock(this.passwordInputError);
                    } else {
                        this.hideBlock(this.passwordInputError);
                    }
                }
            });
        }

        if (this.buttonSkipGp) {
            this.buttonSkipGp.addEventListener('click', (event) => {
                event.preventDefault();
                this.hideGpForm();
                this.showPasswordSetForm();
                this.showCommentDataForm();
            });
        }

        if(this.buttonNewUser) {
            this.buttonNewUser.addEventListener ("click", () => {
                this.showCommentDataForm();
            });
        }

        if(this.buttonRegister) {
            this.buttonRegister.addEventListener ("click", (event) => {
                event.preventDefault();

                this.errFields.forEach(element => {
                    this.hideBlock(element);
                });

                // registration reason is "Kommentar"? => check personal data.
                let errormsg = "";
                let errTarget = [];
                if(CONFIG.registrationReason == "Kommentar" && !document.querySelector('#commentBlock').classList.contains("d-none")) {
                    if (document.querySelector('#comment-userinfo-salutation').value == "") {
                        errormsg += "Bitte wählen Sie Ihre Anrede aus.<br>";
                        this.showBlock('.form-error-comment-salutation');
                        errTarget.push(document.querySelector('label[for="comment-userinfo-salutation"]'));
                    } else {
                        this.fieldSalutation.value = document.querySelector('#comment-userinfo-salutation').value;
                    }
                    if (document.querySelector('#comment-userinfo-firstName').value == "") {
                        errormsg += "Bitte geben Sie Ihren Vornamen ein.<br>";
                        this.showBlock('.form-error-comment-firstName');
                        errTarget.push(document.querySelector('label[for="comment-userinfo-firstName"]'));
                    } else {
                        this.fieldFirstName.value = document.querySelector('#comment-userinfo-firstName').value;
                    }
                    if (document.querySelector('#comment-userinfo-lastName').value == "") {
                        errormsg += "Bitte geben Sie Ihren Nachnamen ein.<br>";
                        this.showBlock('.form-error-comment-lastName');
                        errTarget.push(document.querySelector('label[for="comment-userinfo-lastName"]'));
                    } else {
                        this.fieldLastName.value = document.querySelector('#comment-userinfo-lastName').value;
                    }
                }

                // terms and conditions and guidelines accepted?
                if(!document.getElementById('agbInput').checked || !document.getElementById('commentsInput').checked) {
                    errormsg += "Bitte akzeptieren Sie die AGB und die Richtlinien für Kommentare.<br>";
                    document.querySelector('.form-error-disclaimer').classList.remove('d-none');
                    errTarget.push(document.getElementById('agbInput'));
                }

                // encrypted customerId submitted and ok?
                if(CONFIG.customerId != "" && this.decryptedCustomerId.status == "ok") {
                    this.fieldCustomerId.value =  this.decryptedCustomerId.gp;
                    console.log("GP gesetzt, Feldvalue: " + this.fieldCustomerId.value);
                }

                // Conditions met, no error occurred
                if(errormsg == "") {
                    // get Snowplow cookies
                    let spnozmhn_spId = this.findCookieName("nozmhn_spid");
                    this.buttonSpinner(".register-button");
                    let formData = {
                        email:document.getElementById('emailInput').value,
                        password:document.getElementById('passwordInput').value,
                        registrationReason: CONFIG.registrationReason,
                        redirectUrl: CONFIG.redirectUrl,
                        spnozmhnIdName: spnozmhn_spId,
                        spnozmhnIdValue: Cookies.get(spnozmhn_spId, false),
                        sp: Cookies.get("sp", false),
                        remoteIp: CONFIG.client.ip,
                        userAgent: CONFIG.client.userAgent,
                    };
            
                    formData.salutation = this.fieldSalutation.value ? this.fieldSalutation.value : '';
                    formData.company = this.fieldCompany.value ? this.fieldCompany.value : '';
                    formData.firstName = this.fieldFirstName.value ? this.fieldFirstName.value : '';
                    formData.lastName = this.fieldLastName.value ? this.fieldLastName.value : '';
                    formData.customerId = this.fieldCustomerId.value ? this.fieldCustomerId.value : '';

                    let request = new Request('POST', '/api/register');
                    request.data = formData;
                    request.call(response => {
                        if (response.status == 'ok') {
                            let redirect = false;
                            if (response.data.tac) {
                                this.tacCookie = response.data.tac;
                                redirect = this.redirectUrl;
                            }
                
                            if (response.data.accessToken) {
                                this.utpCookie = response.data.accessToken;
                                redirect = this.redirectUrl;
            
                                if (redirect) {
                                    if (CONFIG.environment.isNewsApp) {
                                        this.hideBlock('#passwordBlock');
                                        this.hideBlock('#existingAboDataBlock');
                                        this.hideBlock('#btnRegisterlBlock');
                                        this.hideBlock('#btnPwShowBlock');
                                        this.hideBlock('#masterDataText');
                                        this.hideBlock('#eMailBlock');
                                        this.showBlock('#appSuccessRegBlock');
                                    } else if (CONFIG.environment.isAudioApp) {
                                        document.location.href = "audioapp-shz://open_app?access_token=" + this.utpCookie;
                                    } else {
                                        // if(CONFIG.customerId != "") {
                                        //     console.log('CustomerId found!\nencrypted value: ' + CONFIG.customerId + '\ndecrypted value: ' + this.decryptedCustomerId);
                                        //     // Hier Customer ID anhängen
                                        //     document.location.href = redirect;
                                        // } else {
                                        document.location.href = redirect;
                                        // }
                                    }
                                } else {
                                    this.updateTacCookie();
                                }
                            } else {
                                this.buttonSpinner('.register-button', false);
                                this.errorPassword(this.errorCode.cookieSet);
                            }
                        } else {
                            this.buttonSpinner('.register-button', false);
                            this.errorPassword(this.errorCode.getByCode(response.code));
                        }
                    }, () => {
                        this.buttonSpinner('.register-button', false);
                        this.errorPassword(this.errorCode.loginError);
                    });
                } else {
                    errTarget[0].scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
                }
            });
        }
    }

    errorPassword(message) {
        let element = document.querySelector('.form-error-password > p.error');
        if (element) {
            element.innerHTML = message;
            this.showBlock('.form-error-password');
        }
    }
    
    showCommentDataForm() {
        if (CONFIG.registrationReason == "Kommentar") {
            if(this.fieldSalutation.value && this.fieldFirstName.value && this.fieldLastName.value && this.fieldCustomerId.value) {
                this.hideBlock('#commentBlock');
            } else {
                if (this.fieldSalutation.value && this.fieldSalutation.value != "Firma") {
                    document.querySelector('#comment-userinfo-salutation').value = this.fieldSalutation.value;
                }
                if (this.fieldFirstName.value) {
                    document.querySelector('#comment-userinfo-firstName').value = this.fieldFirstName.value;
                }
                if (this.fieldFirstName.value) {
                    document.querySelector('#comment-userinfo-lastName').value = this.fieldLastName.value;
                }
            }
        }
    }

    hideGpForm() {
        this.fieldCustomerId.value = '';
        this.hideBlock('#existingAboDataBlock');
        this.hideBlock('#btnPwShowBlock');
    }

    showPasswordSetForm() {
        this.btn2click = document.querySelector('.register-button');
        this.showBlock('#passwordBlock');
        this.hideBlock('#existingAboBlock');
        this.showBlock('#buttonsContainer');
        this.showBlock('#btnRegisterlBlock');
        this.setStatusbar('75');
    }

     findCookieName(spName) {
        let cookiePosition = document.cookie.search(spName);
        let cookiename = document.cookie.substring(cookiePosition, (cookiePosition+16));
        return cookiename;
    }

    sendDoi() {
        let formData = {
            ref: CONFIG.redirectUrl,
        };

        let responseElement = document.querySelector('#appSuccessRegBlock .main-description span');
        let request = new Request('POST', '/api/triggerOptin');
        request.data = formData;
        request.call(response => {
            if (response.status == "ok") {
                responseElement.innerHTML = "E-Mail erfolgreich verschickt.";
            } else {
                responseElement.innerHTML = this.errorCode.doiErrorMail;
            }
        }, () => {
            responseElement.innerHTML = this.errorCode.doiErrorMail;
        });
    }
}