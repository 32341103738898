import { Base } from './base.js';
import { Social } from './social.js';

export class Login extends Base {
    constructor() {
        super();
        this.checkEmailButton = document.querySelector('.check-email-button');
        this.emailInput = document.getElementById('emailInput');
        this.eMailBlock = document.getElementById('eMailBlock');
        this.passwordBlock = document.getElementById('passwordBlock');
        this.forgotPasswordBlock = document.getElementById('forgotPasswordBlock');
        this.btnCheckEmailBlock = document.getElementById('btnCheckEmailBlock');
        this.btnLoginBlock = document.getElementById('btnLoginBlock');
        this.btnSocialLoginGoogle = document.querySelector('.googlebtn');
        this.btn2click = document.querySelector('.check-email-button');
        this.changeEmail = document.querySelector('.change-email');
        this.checkPasswordButton = document.querySelector('#checkLoginButton');

        this.init();
    }

    init() {

        if (CONFIG.customerId != '') {
            this.decryptGp();
        }
        
        this.pushHistoryState('login');

        if(CONFIG.accessToken && !this.utpCookie) {
            this.utpCookie = CONFIG.accessToken;
            if (CONFIG.tacCookie) {
                this.tacCookie = CONFIG.tacCookie;
                document.location.href = this.redirectUrl;
            } else {
                this.updateTacCookie();
            }
        }

        if (this.emailInput) {
            this.emailInput.addEventListener('focus', () => {
                this.hideErrorMessages();
            });

            let regData = Cookies.get('regdata', false);
            if (regData) {
                this.emailInput.value = regData;
                Cookies.remove('regdata', {domain: location.hostname});
            }
        }

        if (this.changeEmail) {
            this.changeEmail.addEventListener('click', () => {
                this.hideAllBlocks();
                this.setStatusbar('25');
                this.showBlock('#eMailBlock');
                this.showBlock('#btnCheckEmailBlock');
                this.checkEmailButton.style.width = "unset";
            });
        }

        if (this.btnSocialLoginGoogle) {
            this.btnSocialLoginGoogle.addEventListener('click', (event) => {
                event.preventDefault();
                let social = new Social();
                social.googleLogin();
            });
        }

        if (this.checkEmailButton) {
            checkEmailButton.addEventListener('click', (event) => {
                event.preventDefault();
                this.checkEmail();
            });
        }

        if (this.checkPasswordButton) {
            this.checkPasswordButton.addEventListener('click', (event) => {
                event.preventDefault();
                this.checkPassword();
            });
        }
        
    }

    enterSubmit(event) {
        if (event.key == 'Enter') {
            event.preventDefault();
            if (this.btn2click) {
                this.btn2click.click();
            }
        }
    }

    errorEmail(message) {
        let element = document.querySelector('.form-error-email > p.error');
        if (element) {
            element.innerHTML = message;
            this.showBlock('.form-error-email');
        }
    }

    errorPassword(message) {
        let element = document.querySelector('.form-error-password > p.error');
        if (element) {
            element.innerHTML = message;
            this.showBlock('.form-error-password');
        }
    }

    errorGp(message) {
        let element = document.querySelector('.form-error-customerId > p.error');
        if (element) {
            if(CONFIG.environment.mandant != "om-online") {
                element.innerHTML = message + "<div class='error' style='padding-top:12px;'>Die übermittelte Kundennummer ist " + this.decryptedCustomerId.gp + ", Ihre Kontaktmöglichkeiten zum Leserservice <a href='https://mein." + CONFIG.environment.mandant + ".de/infowelt/kundenservice/kontaktmoeglichkeiten' class='error' style='color:#CC0300' target='_blank'>finden Sie hier &gt;&gt;</a></div>";
            } else {
                element.innerHTML = message + "<div class='error' style='padding-top:12px;'>Die übermittelte Kundennummer ist " + this.decryptedCustomerId.gp + ", Ihre Kontaktmöglichkeiten zum Leserservice <a href='https://www.om-online.de/kontakt' class='error' style='color:#CC0300' target='_blank'>finden Sie hier &gt;&gt;</a></div>";
            }
            this.showBlock('.form-error-customerId');
            this.hideBlock('.check-e-mail-block');
        }
    }

    checkEmail() {
        if(this.emailInput.value != "") {
            this.buttonSpinner(".check-email-button");
            this.hideErrorMessages();
            let formData = {
                form: 'pianoEmailcheck',
                email: this.emailInput.value.trim(),
            };

            let request = new Request('POST', '/api/validate/email');
            request.data = formData;
            request.call(response => {
                if (response.status == 'ok') {
                    if (response.data.valid) {
                        if (CONFIG.customerId != "" && this.decryptedCustomerId.status == "error") {
                            this.buttonSpinner(".check-login-button", false);
                            this.errorGp(this.errorCode.getByCode(this.decryptedCustomerId.value));             
                        } else {
                            this.setStatusbar('75');
                            if(response.data.exists) {
                                this.showPassword();
                            } else {
                                this.showRegister();
                            } 
                        }
                    } else {
                        this.errorEmail(this.errorCode.invalidEmail);
                    }
                    this.buttonSpinner(".check-email-button", false);
                } else {
                    this.buttonSpinner(".check-email-button", false);
                    this.errorEmail(this.errorCode.emailError);
                }
            }, () => {
                this.buttonSpinner(".check-email-button", false);
                this.errorEmail(this.errorCode.emailError);
            });
        } else {
            this.errorEmail(this.errorCode.missingEmail);
        }
    }

    enrichCustomerId(redirect) {
        console.log('CustomerId found!\nencrypted value: ' + CONFIG.customerId + '\ndecrypted value: ' + this.decryptedCustomerId.gp);
       
        let gpData = {
            email: this.emailInput.value,
            customerId: this.decryptedCustomerId.gp,
        };
        let req = new Request('POST', '/api/add_cutomerid');
        req.data = gpData;
        req.call(resp => {
            if (resp.status == 'ok') {
                document.location.href = redirect;        
            } else {
                this.buttonSpinner(".check-login-button", false);
                this.errorGp(this.errorCode.getByCode(this.decryptedCustomerId.value));
            }
        }, () => {
            this.buttonSpinner(".check-login-button", false);
            this.errorGp(this.errorCode.gpError);
        });
        this.hideBlock('#forgot-password-block');
        this.hideBlock('#btnLoginBlock');
    }

    checkPassword() {
        this.buttonSpinner(".check-login-button");

        let passwordInput = document.getElementById('passwordInput');
        let formData = {
            email: this.emailInput.value,
            password: passwordInput.value,
        };

        let request = new Request('POST', '/api/login');
        request.data = formData;
        request.call(response => {
            if (response.status == 'ok') {
                let redirect = false;
                if (response.data.tac) {
                    this.tacCookie = response.data.tac;
                    redirect = this.redirectUrl;
                }

                if (response.data.accessToken) {
                    this.utpCookie = response.data.accessToken;
                    if (this.missingName) {
                        redirect = this.missingNameRoute;
                    }

                    if (redirect) {
                        if (!CONFIG.environment.isAudioApp) {
                            if(CONFIG.customerId != "" && this.decryptedCustomerId.status == "ok") {
                                this.enrichCustomerId(redirect);
                            } else {
                                document.location.href = redirect;
                            }
                        } else {
                            document.location.href = "audioapp-shz://open_app?access_token=" + this.utpCookie;
                        }
                    } else {
                        console.log("Wir sind in der else! TAC Cookie!");
                        this.updateTacCookie();
                    }
                } else {
                    this.errorPassword(this.errorCode.cookieSet);
                    this.buttonSpinner('.check-login-button', false);
                }
            } else {
                this.buttonSpinner(".check-login-button", false);
                this.errorPassword(this.errorCode.getByCode(response.code));
            }
        }, () => {
            this.buttonSpinner(".check-login-button", false);
            this.errorPassword(this.errorCode.loginError);
        });
    }

    showRegister() {
        this.buttonSpinner(".check-login-button");
        Cookies.set('regdata', this.emailInput.value, { domain: location.hostname });
        if(CONFIG.customerId != "" && this.decryptedCustomerId.status == "ok") {
            Cookies.set('submittedGp', this.decryptedCustomerId.gp, { domain: location.hostname });
        }
        window.location.href = this.registerRoute;
    }

    showPassword() {
        this.hideAllBlocks();
        this.passwordBlock.querySelector('.email-text-bold').innerHTML = this.emailInput.value;
        if(CONFIG.customerId != "" && this.decryptedCustomerId.status == "ok") {
            this.passwordBlock.querySelector('.customerId-text').innerHTML = "Kundennummer: " + this.decryptedCustomerId.gp;
        }
        this.showBlock(this.passwordBlock);
        this.showBlock(this.forgotPasswordBlock);
        this.showBlock(this.btnLoginBlock);
        this.btn2click = document.querySelector('#checkLoginButton');
    }
}